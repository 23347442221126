import React, { useContext } from 'react';
import MyAvatar from '../my/MyAvatar';
import { formatTime } from '../../utils/FormatTime';
import { DynamicProps } from '../../vo/dynamic.vo';
import AuthorSVG from '../svgs/AuthorSVG';
import { RootState, useAppSelector } from '../../redux';
import { PatternOneSVG } from '../svgs/PatternOneSVG';
import { ScrollToTopLink } from '../base/ScrollToTopLink';
import { UserContext } from '../../pages/user';
import { WorkTagProps } from '../../api/workTag/findAll';
import { ViewportProviderContext } from '../../providers/ViewportProvider';
import MyImage from '../my/MyImage';

// tip: 动态组件
export const Dynamic: React.FC<DynamicProps> = ({ data }) => {
  // props
  const { created_at, message, collectible } = data

  // context
  const { account } = useContext(UserContext)
  const { notDesktop } = useContext(ViewportProviderContext)

  // redux
  const { workTags } = useAppSelector((state: RootState) => state.workReducer)

  // other data
  const mainTag = workTags.find(workTag => workTag.id === collectible.tag_id)
  const { color, coverUrl } = mainTag as WorkTagProps

  // const sampleComments: CommentProps[] = [
  //   { 
  //     id: 1, 
  //     username: "User1", 
  //     text: "This is a sample comment.", 
  //     date: formatTime(new Date()), 
  //     likes: 10, 
  //     color: "#D78E8E", 
  //     isLiked: false 
  //   }
  // ]

  return (
    <main className="flex flex-col gap-4">
      <article className={[
        'w-full h-auto relative bg-white border-solid border-[#DDD5D3] flex flex-col gap-4',
        notDesktop ? 'border-0 border-y-[1px] p-5' : 'border-[1px] rounded-[10px] px-14 pt-8 pb-10'
      ].join(' ')}>
        <div className='flex gap-6'>
          {/* 头像 */}
          <MyAvatar size={14} src={account?.avatar} />
          {/* 用户名信息 */}
          <section className='flex flex-col h-14 justify-center'>
            <div className='text-xl text-[#404040] flex gap-2 items-center'>
              <span>{ account?.username }</span>
              { account?.author && <AuthorSVG size='20' /> }
            </div>
            <div className='text-[#DDD5D3] text-xs'>
              <span>{ formatTime(new Date(created_at)) }</span>
            </div>
          </section>
        </div>
        <div className={[
          'flex flex-col gap-5 flex-1 h-full',
          notDesktop ? '' : 'pl-20'
        ].join(' ')}>
          {/* 动态内容 */}
          <section className='text-base text-[#919190]'> 
            { message || "發佈了一個語錄！" }
          </section>
          {/* 动态富文本内容 */}
          {
            collectible && (
              <ScrollToTopLink to={`/home/collectible/${collectible.id}`}>
                <section 
                  style={{ borderColor: color }}
                  className={[
                    'w-full rounded-[10px] border-solid border-2 relative overflow-hidden flex justify-center items-center',
                    notDesktop ? 'min-h-[30vw]' : 'aspect-[7/3]'
                  ].join(' ')}
                >
                  <MyImage src={coverUrl} alt="藏品封面" className='absolute top-0 left-0 opacity-30' />
                  <div 
                    style={{ color }} 
                    className={[
                      'source-han-serif-hk text-xl z-50 w-full h-auto px-20 bg-transparent text-center',
                      notDesktop ? 'py-8' : ''
                    ].join(' ')}
                  >{ collectible.content }</div>
                  <PatternOneSVG color={color} scale={"1.5"} />
                </section>
              </ScrollToTopLink>
            )
          }
        </div>
      </article>
      {/* <div className="comments-section w-full h-auto relative bg-white border-solid border-[#d6cfcd] border-[1px] rounded-[10px] px-[60px] pt-[30px] pb-[40px]">
        <CommentSection comments={sampleComments} />
      </div> */}
    </main>
  )
}