import React, { SyntheticEvent, useEffect, useRef } from 'react';
import { lazyImageObserver } from '../../utils/lazyImageObserver';
interface MyImageProps {
    src?: string
    subSrc?: string
    className?: string
    isErrorOpacity?: boolean 
    style?: React.CSSProperties
    alt?: string
}

const MyImage: React.FC<MyImageProps> = ({ src, subSrc,  style, className, isErrorOpacity = false, alt = 'image by catnip' }) => {
    // ref
    const lazyLoadingRef = useRef<HTMLImageElement>(null)

    // observer: 图片进入视区后开始加载
    useEffect(() => {
        const target = lazyLoadingRef.current
        if(target) {
            lazyImageObserver.observe(target)
        }
        return () => {
            if(target) {
                lazyImageObserver.unobserve((target)) 
            }
        }
    }, [src])

    // method: 图片加载失败
    const imageError = (e: SyntheticEvent<HTMLImageElement, Event>, subSrc: string) => {
        const target = e.currentTarget
        // 防止替换图片也加载失败 循环调用 overflow
        return (target.src === subSrc) ? (target.onerror = null) : (target.src = subSrc)
    }

    // method: 图片加载成功
    const imageLoad = (e: SyntheticEvent<HTMLImageElement, Event>) => {
        const target = e.currentTarget
        target.style.opacity = (target.src !== subSrc) ? '1' : '.3'
    }
  
    return (
        <img
            ref={lazyLoadingRef}
            data-src={src}
            loading='lazy'
            width={'100%'}
            height={'100%'}
            onLoad={isErrorOpacity ? imageLoad : undefined}
            onError={subSrc ? (e) => imageError(e, subSrc) : undefined}
            className={className}
            style={{
                visibility: 'hidden',
                ...style
            }}
            alt={alt}
        />
    )
}

export default MyImage;