export const lazyImageObserver = new IntersectionObserver((entries) => {
    entries.forEach(entry => {
        if (entry.isIntersecting) {
            const image = entry.target as HTMLImageElement;
            const src = image.dataset.src;
            if (src) {
                image.src = src
                image.style.visibility = 'visible'
            }
        }
    })
})