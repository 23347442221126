import { http } from "../request"

interface AccountCreateConnectResponse {
    url?: string
}

interface AccountUnlinkResponse {
    success: boolean
}

export const accountCreateConnectApi = async (accessToken: string) => {
    return await http.post<AccountCreateConnectResponse>("/account/createConnectAccount", {}, {
        headers: {
            Authorization: "Bearer " + accessToken
        }
    })
}

export const AccountLinkPaypalApi = async (accessToken: string) => {
    return await http.post<AccountCreateConnectResponse>("/account/AccountLinkPaypalApi", {}, {
        headers: {
            Authorization: "Bearer " + accessToken
        }
    })
}

export const AccountUnlinkPaypalApi = async (accessToken: string) => {
    return await http.post<AccountUnlinkResponse>("/account/AccountUnlinkPaypalApi", {}, {
        headers: {
            Authorization: "Bearer " + accessToken
        }
    })
}
