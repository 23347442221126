import React, { memo } from 'react'
import FlowerThreeSVG from '../../components/svgs/FlowerThreeSVG'
import FlowerTwoSVG from '../../components/svgs/FlowerTwoSVG'
import { Link } from 'react-router-dom'

const LinkPayPalSuccess: React.FC = memo(() => {
  return (
    <main className='flex flex-col gap-10 items-center py-32 px-10'>
      <section className='w-auto h-auto relative rotate-[20deg]'>
        <FlowerThreeSVG color='#102e24' />
        <aside className='absolute -top-5 -left-12 -rotate-[20deg]'>
          <FlowerTwoSVG color='#102e24' />
        </aside>
      </section>
      <div className='text-center flex flex-col gap-2'>
        <b className='text-xl m-0 p-0 text-[#333]'>成功連結 PayPal 營收帳戶！<Link to="/" className='text-[#2e4f35] bold'>按此</Link> 返回主頁</b>
      </div>
    </main>
  )
})

export default LinkPayPalSuccess