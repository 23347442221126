import React, { Fragment, memo, useContext } from 'react'
import MyButton from '../../components/my/MyButton'
import { RootState, useAppSelector } from '../../redux'
import { ScrollToTopLink } from '../../components/base/ScrollToTopLink'
import { useAuthentication } from '../../hooks/useAuthentication'
import MyAvatar from '../../components/my/MyAvatar'
import MyImage from '../../components/my/MyImage'
import { UserContext } from '../../pages/user'
import AuthorSVG from '../../components/svgs/AuthorSVG'
import { ViewportProviderContext } from '../../providers/ViewportProvider'

interface UserIntroProps {
    userId: number
}

const UserIntro: React.FC<UserIntroProps> = memo(({ userId }) => {
    // hooks
    const { stayTuned } = useAuthentication()

    // redux
    const { moods } = useAppSelector((state: RootState) => state.themeReducer)
    const { user } = useAppSelector((state: RootState) => state.authReducer)

    // context
    const { account, currentMood } = useContext(UserContext)
    const { notDesktop } = useContext(ViewportProviderContext)

    // other data
    const insteadUrl = moods.find(mood => mood.id === account?.mood_id)?.backgroundUrl || ""

    return (
        <>
            <div className={[
                'w-full overflow-hidden relative',
                notDesktop ? 'aspect-[4/3]' : 'aspect-[6/1]',
            ].join(' ')}>
                <MyImage 
                    src={account?.background || ''} 
                    subSrc={insteadUrl}
                    className='w-full h-full object-cover' 
                />
                <b 
                    style={{
                        height: '8rem',
                        overflow: 'hidden',
                        display: '-webkit-box',
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: 4,
                    }}
                    className={[
                        'source-han-serif-hk text-white text-2xl w-full absolute top-1/2 left-0 -translate-y-1/2  select-none px-[15%] text-center',
                        notDesktop ? 'px-20' : ''
                    ].join(' ')}
                >{account?.saying}</b>
            </div>
            <div className={[
                'flex justify-center gap-10 items-start w-2/3 relative',
                notDesktop ? 'pt-20' : ''
            ].join(' ')}>
                <section className='relative'>
                    <MyAvatar 
                        src={account?.avatar}
                        size={32}
                        style={{  borderColor: currentMood?.border }}
                        className={[
                            'border-solid border-4 -translate-y-1/2 absolute transition duration-300',
                            notDesktop ? 'top-0 left-1/2 -translate-x-1/2' : 'top-0 left-0'
                        ].join(' ')}
                    />
                </section>
                <section className={[
                    'flex-1 h-auto flex flex-col gap-3 relative py-10',
                ].join(' ')}>
                    <div className='w-full h-auto flex justify-between'>
                        <div className={[
                            'flex gap-2 items-center',
                            notDesktop ? 'w-full justify-center' : ''
                        ].join(' ')}>
                            <b className='text-2xl h-[2em] flex items-center'>{account?.username}</b>
                            { account?.author && <AuthorSVG size='25' /> }
                        </div>
                        {/* 控件 */}
                        {
                            notDesktop || (
                                <div className='w-auto flex flex-row gap-3'>
                                    {
                                        userId === user?.id
                                        ?(
                                            <ScrollToTopLink to='/user/setting'>
                                                <MyButton type='primary' size='large' className='w-28 h-10'>編輯</MyButton>
                                            </ScrollToTopLink>
                                        )
                                        :(
                                            <Fragment>
                                                <MyButton 
                                                    onClick={stayTuned}
                                                    type='primary' 
                                                    size='large' 
                                                    className='w-28 h-10'
                                                >聊天</MyButton>
                                                <MyButton 
                                                    size='large' 
                                                    onClick={stayTuned}
                                                    style={{ backgroundColor: "#b6aeac" }} 
                                                    className='w-28 h-10 text-white text-sm hover:text-primary'
                                                >留言</MyButton>
                                            </Fragment>
                                        )
                                    }
                                </div>
                            )
                        }
                    </div>
                    <p className='m-0 p-0'>{account?.intro}</p>
                </section>
            </div>
        </>
    )
})

export default UserIntro