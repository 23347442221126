import React, { createContext, memo, useEffect, useState } from 'react'
import Palette from '../../views/user/Palette'
import Background from '../../views/user/Background'
import UserIntro from '../../views/user/UserIntro'
import { useParams } from 'react-router-dom'
import { useAppSelector, RootState, useAppDispatch } from '../../redux'
import { accountFindUniqueAsync } from '../../redux/featrue/accountSlice'
import { collectibleFindStarAsync, setStarCollectibles, setStarCollectiblesLoading } from '../../redux/modules/userSlice'
import { Empty, Spin } from 'antd'
import UserDynamic from '../../views/user/UserDynamic'
import { UserVo } from '../../vo/user.vo'
import { accountFindUniqueApi } from '../../api/account/findUnique'
import { MoodTheme } from '../../redux/featrue/themeSlice'
import { parseNormal } from '../../utils/parseCollectibles'
import { CollectibleCarousel } from '../../components/collectible/CollectibleCarousel'
import { BookList } from '../../components/book/BookList'
import { workFindMyShelfAsync } from '../../redux/modules/booksSlice'
import useAccessToken from '../../hooks/useAccessToken'

export type UserContextType = {
  account: UserVo | null
  currentMood: MoodTheme | null
}

export const UserContext = createContext<UserContextType>({ account: null, currentMood: null })

const User: React.FC = memo(() => {
  // router
  const params = useParams() as { id: string }

  // redux
  const dispatch = useAppDispatch()
  const { moods } = useAppSelector((state: RootState) => state.themeReducer)
  const { user } = useAppSelector((state: RootState) => state.authReducer)
  const { accounts } = useAppSelector((state: RootState) => state.accountReducer)
  const { starInitialCollectibles, starCollectibles, starCollectiblesLoading } = useAppSelector((state: RootState) => state.userReducer)
  const { shelfBooks, shelfBooksLoading } = useAppSelector((state: RootState) => state.booksReducer)

  // responsive data
  const [account, setAccount] = useState<UserVo | null>(null)

  // hook
  const { accessToken } = useAccessToken()

  // other data
  const currentMood = moods.find(mood => mood.id === account?.mood_id) || null
  const tabs = [
    { id: 1, name: "書架" },
    { id: 2, name: "動態" }
  ]

  // mounted: 获取用户信息
  useEffect(() => {
    async function fetchAccount() {
      if(user?.id !== Number(params.id)) {
        const account = await accountFindUniqueApi(Number(params.id))
        setAccount(account)
      } else {
        setAccount(user)
      }
    }
    fetchAccount()
  }, [params?.id, user])

  // mounted: 获取当前用户书架上的书籍
  useEffect(() => {
    if(account) {
      dispatch(workFindMyShelfAsync({
        userId: account.id,
        accessToken
      }))
    }
  }, [dispatch, account, accessToken])

  // responsive data
  const [selectedTab, setSelectedTab] = useState<typeof tabs[number]["id"]>(tabs[0].id)

  // method: 点击切换tab
  const handleChangeTab = (tab: typeof tabs[number]) => {
    setSelectedTab(tab.id)
  }

  // mounted: 获取用户信息
  useEffect(() => {
      if(!accounts.find(account => account.id === Number(params.id))) {
          dispatch(accountFindUniqueAsync(Number(params.id)))
      }
  }, [accounts, params.id, dispatch])

  // mounted: 获取用户已收藏的藏品
  useEffect(() => {
    dispatch(setStarCollectiblesLoading(true))
    dispatch(collectibleFindStarAsync(Number(params.id))).then(() => {
      dispatch(setStarCollectiblesLoading(false))
    })
  }, [dispatch, params.id])

  // watch: 解析 collectibles
  useEffect(() => {
    if(starInitialCollectibles) {
      dispatch(setStarCollectibles(parseNormal(starInitialCollectibles)))
    }
  }, [starInitialCollectibles, dispatch])

  return (
    <UserContext.Provider value={{ account, currentMood }}>
      <main className='relative w-full h-auto'>
        {/* 用户简介 */}
        <section className='flex flex-col items-center'>
          <UserIntro userId={Number(params.id)} />
        </section>
        <hr className='border-b-[#d4d4cc] border-transparent mx-10'/>
        {/* 调色板 */}
        {
          (user?.id === Number(params.id)) && (
            <>
              <section className='flex flex-col justify-center items-center gap-2 py-6'>
                <Palette />
              </section>
              <hr className='border-b-[#d4d4cc] border-transparent mx-10'/>
            </>
          )
        }
        <div className='w-full h-auto flex flex-row'>
          <div className='w-full mx-auto flex flex-col gap-5'>
            {/* 语录轮播 */}
            <section>
              {
                starCollectiblesLoading
                ? <Spin className="w-full py-36" />
                : (starCollectibles && starCollectibles.length > 0)
                  ? <CollectibleCarousel data={starCollectibles} navigation={{ color: currentMood?.dark }} />
                  : null
                  // : <Empty className='mx-auto py-20' description = "暫時沒有已收藏產品"/>
              }
            </section>
            {/* todo: 用户动态 / 用户创作 */}
            <section className='w-full h-auto'>
              <div className='flex justify-center gap-5 mb-5'>
                {
                  tabs.map(tab => (
                    <span
                      key={tab.id}
                      onClick={() => handleChangeTab(tab)}
                      style={{
                        backgroundColor: selectedTab === tab.id ? currentMood?.dark:"transparent",
                        color: selectedTab === tab.id ? "#fff":"#102e24"
                      }}
                      className='rounded-lg px-7 py-2 cursor-pointer transition duration-500'
                    >{tab.name}</span>
                  ))
                }
              </div>
              { 
                selectedTab === 1 && (
                  <BookList 
                    data={shelfBooks || []}
                    loading={shelfBooksLoading}
                    showNavBar={false}
                  />
                ) 
              }
              { selectedTab === 2 && <UserDynamic /> }
            </section>
          </div>
          {/* <div className='w-1/4 flex flex-col gap-5'> */}
            {/* 个人面板 */}
            {/* <section>
              <UserPanel />
            </section> */}
            {/* todo: 留言板 */}
            {/* <section>
              <UserMessage />
            </section> */}
          {/* </div> */}
        </div>
        {/* 背景光晕 */}
        <Background />
      </main>
    </UserContext.Provider>
  )
})

export default User