import { Route } from './'
import { lazy, Suspense } from 'react'
import Loading from '../components/my/MySpin'
import LinkPayPalSuccess from '../pages/user/LinkPayPalSuccess'

const User = lazy(() => import('../pages/user'))
const Setting = lazy(() => import('../pages/user/setting'))
const Notice = lazy(() => import('../pages/user/notice'))

export const user: Route[] = [
    { 
        path: '/user/:id', 
        element: (
            <Suspense fallback={<Loading />}>
                <User />
            </Suspense>
        ),
        meta: { check: true }
    },
    { 
        path: '/user/setting', 
        element: (
            <Suspense fallback={<Loading />}>
                <Setting />
            </Suspense>
        ),
        meta: { flowers: false, check: true },
    },
    { 
        path: '/user/notice', 
        element: (
            <Suspense fallback={<Loading />}>
                <Notice />
            </Suspense>
        ),
        meta: { flowers: false, check: true },
    },
    { 
        path: '/user/linkPayPal/success', 
        element: (
            <Suspense fallback={<Loading />}>
                <LinkPayPalSuccess />
            </Suspense>
        ),
        meta: { flowers: true, check: true },
    },
]